import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Button, Dropdown, DropdownItem, Heading, LoadingSpinner, Paragraph, Textarea } from 'suomifi-ui-components'
import vceApi from '../../vceApi';
import { AuthProvider } from '../../provider/AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { TestDataAddress, TestDataCompany, TestDataCompanyRole, TestDataPerson } from '../../api/vce-api/vce-api';
import IssueCredential from '../../components/IssueCredential';

type Props = {}

const generateLpid = (company: TestDataCompany) => {
  const now = new Date();
  const exp = new Date();
  exp.setDate(exp.getDate() + 30);
  return JSON.stringify({
    "id": `LPID-${company.ID}`,
    // "credentialSubject": {
    //   "legal_person_id": "FIFPRO." + company.Code,
    //   "legal_person_name": company.CompanyName,  
    // },
    "legal_person_id": "FIFPRO." + company.Code,
    "legal_person_name": company.CompanyName,
    "issuer_id": "FIFPRO.0244683-1", // real prh company code, is it ok here?
    "issuer_name": "Mini-PRH",
    "issuer_country": "Finland",
    "issuance_date": now.toISOString(),
    "expiry_date": exp.toISOString(),
    "credential_status": company.Status ? "deactive" : "active", 
    "authentic_source_id": "EU2233344.HRB223344",
    "authentic_source_name": "National Business Register",
    "credential_schema": "http://lpid_schema.org" // should it use this or not?
    // "schema_id": "LPID",
    // "schema_version": 1,
    // "schema_location": "https://test.minisuomi.fi/api/templates/LPIDSdJwt/schema.json"
  }, null, 2);
}




const generatePid = (person: TestDataPerson) => {
  const now = new Date();
  const exp = new Date();

  const birth_date = new Date(person.Birthday);

  const over15 = new Date(now)
  over15.setFullYear(now.getFullYear()-15);
  const over18 = new Date(now)
  over15.setFullYear(now.getFullYear()-18);
  const over20 = new Date(now);
  over20.setFullYear(now.getFullYear()-20);

  exp.setDate(exp.getDate() + 30);
  return JSON.stringify({
    "id": `PID-${person.ID}`,
    "family_name": person.LastName,
    "given_name": person.FirstNames, // Current first name(s), including middle name(s), of the PID User.
    "birth_date": person.Birthday.substring(0, 10), // Day, month, and year on which the PID User was born.
    "age_over_18": over18.getTime() < birth_date.getTime(),
    //"issuer_name": "Mini-PRH",
    "issuer_country": "FI", // ISO 3166-1 Alpha-2 for example "FI"  
    "issuance_date": now.toISOString(), // Date (and possibly time) when the PID was issued.
    "expiry_date": exp.toISOString(), // Date (and possibly time) when the PID will expire.
    "issuing_authority": "Mini-DVV", // Name of the administrative authority that has issued this PID instance, or the ISO 3166 Alpha-2 country code of the respective Member State if there is no separate authority authorized to issue PIDs.
    "age_over_15": over15.getTime() < birth_date.getTime(), // Additional current age attestations, NN ≠ 18.
    "age_over_20": over20.getTime() < birth_date.getTime(), // Additional current age attestations, NN ≠ 18.
    "gender": person.Gender, // PID User's gender, using a value as defined in ISO/IEC 5218. // 0 = Not known; 1 = Male; 2 = Female; 9 = Not applicable.
    "nationality": person.Nationality.substring(0,2).toUpperCase()||"FI" // Alpha-2 country code as specified in ISO 3166-1, representing the nationality of the PID User. For example "FI"
  }, null, 2);
}

const generateEucc = (company: TestDataCompany, persons: TestDataPerson[], companyRoles: TestDataCompanyRole[], addresses: TestDataAddress[]) => {
  const now = new Date();
  const exp = new Date();
  exp.setDate(exp.getDate() + 30);
  const amount = company.CompanyName.length * 75321;

  if (addresses.length === 0) {
    addresses.push({ Address: "Testikuja 12 A 3", PostalCode: "70100", PostalDistrict: "Kuopio", Coordinates: "", Code: "", CountryCode: "", EndDate: "", StartDate: "", ForeignCity: "", ForeignCityAndCountry: "", OwnerID: "", ID: "", Type: 0})
  }
  const registeredAddress = addresses[0];
  const postalAddress = addresses.length > 1 ? addresses[1] : addresses[0];

  const legalPresentatives: {role:string, legalEntityId: string, scopeOfRepresentation: string, family_name: string, given_name: string, birth_date: string}[] = [];

  for (const role of companyRoles) {
    const person = persons.find(x => x.Code === role.Person);
    legalPresentatives.push({
      "role": role.Role, //. "CTO", 
      "legalEntityId": role.Company, // "X12345678",
      "scopeOfRepresentation": role.Role === "TJ" ? "Alone" : "Jointly", // TODO // "Alone",
      "family_name": person?.LastName || "",
      "given_name": person?.FirstNames || "",
      "birth_date": person?.Birthday || ""
    })
  }
  
  

  return JSON.stringify({
    "id": `EUCC-${company.ID}`,
    "legalName": company.CompanyName,
    "legalFormType": company.CompanyForm,
    "legalIdentifier": "FIFPRO."+company.Code,
    "registeredAddress": //"{\"post_code\": \"70839\",\"post_name\": \"Finnland\",\"thoroughfare\": \"Test-Platz\",\"locator_designator\": \"1\"}",
    {
      "post_code": registeredAddress.PostalCode,
      "post_name": registeredAddress.PostalDistrict,
      "thoroughfare": registeredAddress.Address
    },
    "postalAddress": //"{\"post_code\": \"70839\", \"post_name\": \"Finnland\",\"thoroughfare\": \"Test-Platz\", \"locator_designator\": \"1\"}",
    {
      "post_code": postalAddress.PostalCode,
      "post_name": postalAddress.PostalDistrict,
      "thoroughfare": postalAddress.Address
    },
    "registrationDate": company.DateOfRegistration,
    "shareCapital": //"{\"amount\":\"100.000.0000\",\"currency\": \"EUR\"}",
    {
      "amount": amount,
      "currency": "EUR"
    },
    "legalEntityStatus": "active",
    "legalRepresentative": legalPresentatives, //"[{\"role\":\"CTO\", \"legalEntityId\":\"X12345678\",\"scopeOfRepresentation\": \"Alone\",\"family_name\": \"TestaWallet\",\"given_name\":\" Person\",\"birth_date\":\"01.01.1970\" },{\"role\":\"Procurist\",\"legalEntityId\":\"Y9090909090\", \"scopeOfRepresentation\": \"Jointly\",\"family_name\": \"TestPerson EWC2\",\"given_name\":\" Person2\",\"birth_date\":\"1970-01-01\"},{\"role\":\"Procurist\",\"legalEntityId\":\"Z121212\", \"scopeOfRepresentation\": \"Jointly\"}]",
    "legalEntityActivity": //"[{\"code\": \"001\",\"businessDescription\": \"Die Entwicklung, Herstellung , Lieferung, etc \"},{\"code\": \"002\",\"businessDescription\": \"Developing & Services \"}]",
    [{
      "code": "001",
      "businessDescription": "test"
    }],
    "contactPoint": //"{\"contactPage\": \"http://bosch.com\", \"hasEmail\": \"contact@page.com\",\"hasTelephone\": \"012  2102012\"}",
    {
      "contactPage": "",
      "hasEmail": "",
      "hasTelephone": ""
    },
    "issuer_name": "Mini-PRH", //"QTSP Provider",
    "issuer_id": "FIFPRO.0244683-1", // real prh company code, is it ok here?
    "issuer_country": "FIN", //"DEU",
    "issuance_date": now.toISOString(),
    "expiry_date": exp.toISOString(),
    "authentic_source_id": "EU2233344.HRB2233446",
    "authentic_source_name": "National Business Register / BRIS / Transparent Register"
  }, null, 2);
}

const CompanyView = (props: Props) => {
  const { t/*, i18n*/ } = useTranslation();

  const location = useLocation()
  //const [credentialOfferUri, setCredentialOfferUri] = useState<string | undefined>(undefined);  

  //const person = 
  //const [personId, setPersonId] = useState<TestDataCompany|null>(null); 
  const [companies, setCompanies] = useState<TestDataCompany[]>([]);
  const [persons, setPersons] = useState<TestDataPerson[]>([]);
  const [personId, setPersonId] = useState<string>("")
  const [company, setCompany] = useState<TestDataCompany|null>(null);
  const [companyRoles, setCompanyRoles] = useState<TestDataCompanyRole[]>([]);
  const [addresses, setAddresses] = useState<TestDataAddress[]>([]); // company related addresses

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);

  const [isReady, setIsReady] = useState<boolean>(false);

  const [lpidCredentialSubject, setLpidCredentialSubject] = useState<string>("");
  const [pidCredentialSubject, setPidCredentialSubject] = useState<string>("");
  const [euccCredentialSubject, setEuccCredentialSubject] = useState<string>("");
  //const [legalPresentatives, setLegalPresentatives] = useState(null); // TODO
  
  // const issueLpid = () => {

  // }

  // const issueEucc = () => {
    
  // }

  useEffect(() => {

    // TODO: make single function to return all the required data...

    const asyncFunc = async () => {
      console.log("async load1")

      if (isReady) setIsReady(false);
      setIsLoading(true);
      
      // Get person id from session info
      const sessionResponse = await vceApi.sessions.getSessionInfo({ credentials: "include" });
      if (sessionResponse.data === null)
        sessionResponse.data = await sessionResponse.json()
      const sessionInfo = sessionResponse.data;

      
      const personId = sessionInfo.personId;
      if (!personId) {
        // todo show alert
        alert("personId is required for listing companies, please login with finnish PID or suomi.fi login.")
        setPersonId("");
        return;
      }

      setPersonId(personId);

      // Get companies for the person
      const companyRolesResponse = await vceApi.testdata.getPersonCompanyRoles(personId);
      const data = await companyRolesResponse.json() as TestDataCompanyRole[];
      
      //setCompanyRoles(data);
      
      const companyIds = data.map(x => x.Company);
      const companiesResponse = await vceApi.testdata.getCompanies({ companyIDsCSV: companyIds.join(",") });

      setCompanyRoles(data);
      setCompanies(companiesResponse.data)

      setIsLoading2(true);
      setIsLoading(false);
    }
    // if (!isLoading)
    // setIsLoading(true);
    asyncFunc();
  }, [])

  useEffect(() => {
    
    if (company !== null) {
      console.log("async load2");
      const asyncFunc = async () => {
        
        const rolesResponse = await vceApi.testdata.getCompanyRoles(company.Code);
        if (rolesResponse.data === null)
          rolesResponse.data = await rolesResponse.json() as TestDataCompanyRole[]
        console.log(rolesResponse.data);

        const persons: string[] = rolesResponse.data.map(x => x.Person);
        const personsResponse = await vceApi.testdata.getPersons({ personCodesCSV: persons.join(",") });
        //const personsData = await personsResponse.json()
        if (personsResponse.data === null)
          personsResponse.data = await personsResponse.json() as TestDataPerson[]
        console.log(personsResponse.data);
        
        //const ownerIDs: string[] =  

        const addressessResponse = await vceApi.testdata.getAddresses({ownerIDsCSV: company.ID})
        if (addressessResponse.data === null)
          addressessResponse.data = await addressessResponse.json() as TestDataAddress[]
        console.log(addressessResponse.data)

        setAddresses(addressessResponse.data);
        setCompanyRoles(rolesResponse.data);  
        setPersons(personsResponse.data);  
        
        if (!isLoading) {
          setIsLoading2(false);
          setIsReady(true);
        }
      };
      asyncFunc();
    }
  }, [company, isLoading])

  

  useEffect(() => {
    if (!isLoading && !isLoading2 && persons && company) {
      setLpidCredentialSubject(generateLpid(company));
      const person = persons.find(x => x.Code === personId);
      if (person)
        setPidCredentialSubject(generatePid(person));
      setEuccCredentialSubject(generateEucc(company, persons, companyRoles, addresses));
    }
  }, [isLoading, isLoading2, persons, company, companyRoles, addresses, personId]);
  

  // useEffect(() => {
  //   if (company) {

  //   }
  // }, [company])





    // let state =  window.crypto.randomUUID().replace(/-/g,"");

    // if (credentialOfferUri === undefined) {
    //   // in case of it have already authenticated, it could use pre-authorized mode...
    //   vceApi.issuers.issueSuomiFiLpid("prh", { useCredentialOfferUri: true }, { credentials: 'include'}).then(async x => {
    //     debugger;
    //     const url = await x.text()
    //     console.log(`credentialOfferUri: ${url}`); // todo pitäisiköhän ohjata suomifi tunnistautumiseen? vai mikä tämä oli?
    //     //x.data.redirectUri = "https://"
    //     setCredentialOfferUri(url);
        
    //   }).catch(x => console.log(x));
    // }
    


  if (!AuthProvider.isAuthenticated) {
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`}></Navigate>
  }

  return (
    <div>
      <Heading variant='h3'>{t("route.company")}</Heading>

      <Paragraph>{ t("companyView.info")}</Paragraph>

      { isLoading &&
        <LoadingSpinner forwardedRef={undefined} text="Loading companies..."></LoadingSpinner> 
      }

      { !isLoading && (!companyRoles || companyRoles.length === 0) &&
        <Paragraph>{ t("companyView.noCompanies") }</Paragraph>
      }

      { companies && companies.length > 0 && 
        <Dropdown
          labelText={t("companyView.companySelection")}
          visualPlaceholder={t("companyView.companyPlaceHolder")}
          value={ company?.Code }
          onChange={ (newValue) => { setCompany(companies.find(x => x.Code === newValue)||null) }}
        >
          {companies.map((company) => (
            <DropdownItem key={company.ID} value={company.Code}>
              {company.Code} {company.CompanyName} 
            </DropdownItem>
          ))}
        </Dropdown>
      }

      { company !== null &&
        <>
          <Heading variant='h3'>{ company.CompanyName }</Heading>
          <Heading variant='h4'>Basic info</Heading>
          <table>
            <tbody>
              <tr><td>ID:</td><td>{ company.ID }</td></tr>
              <tr><td>EUID:</td><td>FIFPRO.{ company.Code }</td></tr>
              <tr><td>Company form: </td><td>{ company.CompanyForm }</td></tr>
              <tr><td>PlaceOfBusiness: </td><td>{ company.PlaceOfBusiness }</td></tr>
              <tr><td>Status: </td><td>{ company.Status }</td></tr>
              <tr><td>State: </td><td>{ company.State }</td></tr>
              <tr><td>IndustryCode: </td><td>{ company.IndustryCode }</td></tr>
              <tr><td>Date Of Registration: </td><td>{ company.DateOfRegistration }</td></tr>
              {/* <tr><td>PeppolParticipantID: </td><td>{ company.PeppolParticipantID }</td></tr> */}
            </tbody>
          </table>

          { isLoading2 &&
            <LoadingSpinner forwardedRef={undefined} text="Loading company roles..."></LoadingSpinner> 
          }

          
          {
            !isLoading2 && addresses && addresses.length > 0 &&
            <>
              <Heading variant='h4'>Addresses</Heading>
             
              { addresses.map(address => 
              <table key={address.ID}>
                <tbody>
                  <tr>
                    <td>Address:</td>
                    <td>{address.Address}</td>
                  </tr>
                  <tr>
                    <td>Postal district:</td>
                    <td>{address.PostalDistrict}</td>
                  </tr> 
                  <tr>
                    <td>Postal code:</td>
                    <td>{address.PostalCode}</td>
                  </tr> 
                  <tr>
                    <td>Country:</td>
                    <td>Finland</td>
                  </tr> 
                  {/* <tr>
                    <td>valid:</td>
                    <td>{address.StartDate} - { address.EndDate }</td>
                  </tr>  */}
                  {/* <tr>
                    <td><br/></td> <td></td>
                  </tr> */}
                </tbody>
              </table> )
              }

            </>
          }

          { !isLoading2 && companyRoles && companyRoles.length > 0 && persons &&
          <>
            <Heading variant='h4'>Roles</Heading>

            <table style={{ textAlign:"left"}}>
              <thead>
                <tr>
                  <th>Firstnames</th>
                  <th>LastName</th>
                  <th>Person ID</th>
                  <th>Role</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                { companyRoles.map(x => {
                  const person = persons.find(p => p.Code === x.Person);
                  return <tr key={x.ID}>
                    <td>{person?.FirstNames}</td>
                    <td>{person?.LastName}</td>
                    <td>{x.Person}</td>
                    <td>{x.Role}</td>
                    <td>{x.Type}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </>
          }
        </>
      }

      {/* <Heading variant='h3'>{t("companyView.title")}</Heading>
      <Paragraph>{t("companyView.info")}</Paragraph>

      <Box sx={{
      marginTop: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <div>
        {credentialOfferUri &&
          <>
            
            <div style={{ height: "auto", margin: "0 auto", maxWidth: 192, width: "100%" }}>
              <a href={credentialOfferUri}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={credentialOfferUri}
                  viewBox={`0 0 256 256`}
                />
              </a>
              <p style={{textAlign: "center", margin: 0}}>LPID</p>
            </div>
          </>
        }
      </div>
    </Box> */}

      
    {/* <div style={{columnCount: 2}}> */}
    { isReady && !isLoading2 && lpidCredentialSubject && 
    <>
      <hr style={{ margin: "40px 0", borderWidth: "1px solid #ccc" }}/>
    
      {/* <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}> */}
      <IssueCredential credentialConfigurationId='LPIDSdJwt' credentialType='LPID' issuer='prh' credentialSubject={lpidCredentialSubject}></IssueCredential>
      <br />
      <Textarea readOnly fullWidth height={60} rows={10} labelText="LPID credential subject" value={ lpidCredentialSubject } onChange={e => { setLpidCredentialSubject(e.target.value) }} />
      {/* </div> */}
    </>
    }

    { isReady && euccCredentialSubject && 
    <>
      <hr style={{ margin: "40px 0", borderWidth: "1px solid #ccc" }}/>
    
      <IssueCredential credentialConfigurationId='EUCCSdJwt' credentialType='EUCC' issuer='prh' credentialSubject={euccCredentialSubject}></IssueCredential>
      <br/>
      <Textarea readOnly fullWidth height={60} rows={20} labelText="EUCC credential subject" value={ euccCredentialSubject } onChange={e => { setEuccCredentialSubject(e.target.value) }} />
    </>
    }
    {/* </div> */}

    
    {/* <Button onClick={issueLpid}>Issue LPID</Button> */}

    {/* <Button onClick={issueEucc}>Issue EUCC</Button> */}
    


    {/* <div style={{columnCount: 2}}> */}
    { isReady && !isLoading2 && pidCredentialSubject && 
    <>
      <hr style={{ margin: "40px 0", borderWidth: "1px solid #ccc" }}/>
    
      <h3>Mini-DVV issued PID credential (not issued by Mini-PRH)</h3>

      <IssueCredential credentialConfigurationId='PIDSdJwt' credentialType='PID' issuer='dvv' credentialSubject={pidCredentialSubject}></IssueCredential>
      <br />
      <Textarea readOnly fullWidth height={60} rows={10} labelText="PID credential subject" value={ pidCredentialSubject } onChange={e => { setPidCredentialSubject(e.target.value) }} />
      {/* </div> */}
    </>
    }

    </div>
  )
}

export default CompanyView