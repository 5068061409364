import React, { useEffect, useState } from 'react'
import vceApi from '../vceApi';
import QRCode from 'react-qr-code';

type Props = { 
  issuer: string,
  credentialType: string,
  credentialConfigurationId: string,
  credentialSubject: string //{ [key: string]: unknown }
}

/** 
 * DO NOT USE THIS IN PRODUCTION! 
 * Issuing should happen in back channel, and there should be also issuer related apikey or something else to prevent anyone to issue credentials who can access the API
 * MiniSuomi environment has only test data 
 **/
const IssueCredential = ({ issuer, credentialType, credentialConfigurationId, credentialSubject }: Props) => {

  const [error, setError] = useState<string>("");
  const [credentialOfferUrl, setCredentialOfferUrl] = useState<string>("");
  //const [credentialType, setCredentialType] = useState<string|undefined>(undefined);

  useEffect(() => {
    console.log("IssueCredential effect");
    const issue = async () => {
      if (!issuer || !credentialConfigurationId) return;
      try {
  
        const issueResponse = await vceApi.issuers.issue(issuer, credentialConfigurationId, JSON.parse(credentialSubject), { validFrom: new Date().toISOString() /*validFrom?.toISOString()*/, validTo: undefined /*validTo?.toISOString()*/});
        if (!issueResponse.data)
          issueResponse.data = await issueResponse.text();
  
        const offerUri = issueResponse.data;
        
        setCredentialOfferUrl(offerUri);
      }
      catch(e) {
        console.log(e);
        setError(JSON.stringify(e));
      }
    }
    issue();
  }, []);// [credentialConfigurationId,issuer,credentialSubject]);

  // TODO renew button and time out (5min)

  return (
    <div>
      <span style={{ color: "red" }}>{ error }</span>
      
      { credentialOfferUrl && !error &&
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 192, width: "100%" }}>
          <a href={credentialOfferUrl}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={credentialOfferUrl}
              viewBox={`0 0 256 256`}
            />
          </a>
          <p style={{textAlign: "center", margin: 0}}>{credentialType}</p>
        </div>
      }
    </div>
  )
}

export default IssueCredential